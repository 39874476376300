












































import { Component, Prop, Vue } from "vue-property-decorator";

import EntityCard from "@/settings/components/networks-management/EntityCard.vue";
import NetRevenueTable from "@/settings/components/networks-management/net-revenue/NetRevenueTable.vue";
import NetRevenueForm from "@/settings/components/networks-management/net-revenue/NetRevenueForm.vue";
import NetworkIntegrationResponseModel from "@/settings/models/networks-management/NetworkIntegrationResponseModel";
import NetRevenueRequestModel, {
  NetByPeriodModel,
} from "@/settings/models/networks-management/NetRevenueRequestModel";
import NetRevenueResponseModel from "@/settings/models/networks-management/NetRevenueResponseModel";

@Component({
  components: {
    EntityCard,
    NetRevenueTable,
    NetRevenueForm,
  },
})
export default class NetRevenueView extends Vue {
  @Prop() networkIntegration!: NetworkIntegrationResponseModel;

  visible = false;
  localValue = new NetRevenueRequestModel();

  get savingInProgress(): boolean {
    return this.$store.state.networksManagementStore
      .savingNetRevenuesInProgress;
  }

  get netRevenues(): Array<NetRevenueResponseModel> {
    return this.$store.state.networksManagementStore.netRevenues;
  }

  async created() {
    await this.$store.dispatch("loadNetRevenues", this.networkIntegration.id);

    this.localValue.netsByPeriod = NetByPeriodModel.ofArray(this.netRevenues);
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (value && !this.netRevenues.length) {
      this.localValue.netsByPeriod = [new NetByPeriodModel("2018-09-01")];
    }
  }

  handleAddingPeriod() {
    this.localValue.netsByPeriod.push(new NetByPeriodModel());
  }

  async handleSaving() {
    if (this.netRevenues.length) {
      await this.$store.dispatch("updateNetRevenues", {
        networkIntegrationId: this.networkIntegration.id,
        payload: NetRevenueRequestModel.ofRequest(this.localValue),
      });
    } else {
      this.localValue.networkIntegrationId = this.networkIntegration.id;
      await this.$store.dispatch(
        "createNetRevenues",
        NetRevenueRequestModel.ofRequest(this.localValue)
      );
    }

    this.handleChangeVisible(false);
  }

  handleDeletingAll() {
    this.$store.dispatch("deleteAllNetRevenues", this.networkIntegration.id);
  }
}
