import NetRevenueResponseModel from "./NetRevenueResponseModel";

export default class NetRevenueRequestModel {
  constructor(
    public netsByPeriod: Array<NetByPeriodModel> = [new NetByPeriodModel()],
    public networkIntegrationId?: number
  ) {}

  static ofRequest(model: NetRevenueRequestModel): NetRevenueRequestModel {
    return new NetRevenueRequestModel(
      NetByPeriodModel.ofRequest(model.netsByPeriod),
      model.networkIntegrationId
    );
  }
}

export class NetByPeriodModel {
  constructor(
    public dateFrom: string | Date | null = null,
    public dateTo: string | Date | null = null,
    public net: number = 30,
    public isDateFromMenuActive?: boolean,
    public isDateToMenuActive?: boolean
  ) {}

  static ofArray(
    items: Array<NetRevenueResponseModel>
  ): Array<NetByPeriodModel> {
    return items.map(
      (item) => new NetByPeriodModel(item.dateFrom, item.dateTo, item.net)
    );
  }

  static ofRequest(items: Array<NetByPeriodModel>): Array<NetByPeriodModel> {
    return items.map(
      (item) => new NetByPeriodModel(item.dateFrom, item.dateTo, item.net)
    );
  }
}
