










import { Component, Vue } from "vue-property-decorator";

import NetRevenueResponseModel from "@/settings/models/networks-management/NetRevenueResponseModel";

@Component
export default class NetRevenueTable extends Vue {
  readonly headers = [
    {
      text: this.$lang("appAdmin.networksManagement.netRevenue.dateFrom"),
      value: "dateFrom",
    },
    {
      text: this.$lang("appAdmin.networksManagement.netRevenue.dateTo"),
      value: "dateTo",
    },
    {
      text: this.$lang("appAdmin.networksManagement.netRevenue.net"),
      value: "net",
    },
  ];

  get isLoading(): boolean {
    return this.$store.state.networksManagementStore.netRevenuesIsLoading;
  }

  get netRevenues(): Array<NetRevenueResponseModel> {
    return this.$store.state.networksManagementStore.netRevenues;
  }

  emitDeleteAll() {
    this.$emit("deleteAll");
  }
}
